import React, { useState } from "react";

const ModalNotas = ({ notas, isOpen, onClose, onDelete }) => {
  const [notasState, setNotasState] = useState(notas); // Armazena o estado das notas

  if (!isOpen) return null;

  const handleMarkAsRead = (id) => {
    setNotasState((prevNotas) =>
      prevNotas.map((nota) =>
        nota.id === id ? { ...nota, nova: false } : nota
      )
    );

    // Chamada à API para concluir a nota
    fetch(`${process.env.REACT_APP_API_URL}/notas/${id}/concluir`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error("Erro ao concluir nota");
        return response.json();
      })
      .then((data) => {
        console.log("Nota concluída:", data);

        // Agora, envie a notificação para todos os correspondentes
        fetch(`${process.env.REACT_APP_API_URL}/notificartodos`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            notaId: id,
            mensagem: `A nota do cliente foi concluída.`,
          }),
        })
          .then((response) => {
            if (!response.ok) throw new Error("Erro ao enviar notificação");
            return response.json();
          })
          .then((data) => console.log("Notificação enviada:", data))
          .catch((error) =>
            console.error("Erro ao enviar notificação:", error)
          );
      })
      .catch((error) => console.error(error));
  };

  const handleDelete = (id) => {
    // Chamada à API para deletar a nota
    fetch(`${process.env.REACT_APP_API_URL}/notas/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (!response.ok) throw new Error("Erro ao deletar nota");

        // Atualiza o estado local após a exclusão
        setNotasState((prevNotas) =>
          prevNotas.filter((nota) => nota.id !== id)
        );
        console.log("Nota deletada:", id);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 text-white rounded-lg shadow-lg p-6 w-11/12 max-w-md">
        <h2 className="text-2xl font-semibold mb-4">Notas do Cliente</h2>
        <ul className="space-y-4">
          {notasState && notasState.length > 0 ? (
            notasState.map((nota) => (
              <li
                key={nota.id}
                className="flex justify-between items-center p-4 border border-gray-700 rounded-md"
              >
                <div className="flex-1">
                  <p className="text-lg font-medium">
                    {nota.texto || "Sem conteúdo"}
                  </p>
                  <p className="text-sm text-gray-400">
                    Criado por: {nota.criado_por_id || "Desconhecido"}
                  </p>
                  <p className="text-xs text-gray-500">
                    {nota.data_criacao
                      ? new Date(nota.data_criacao).toLocaleString()
                      : "Data desconhecida"}
                  </p>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleMarkAsRead(nota.id)}
                    className="bg-green-600 text-white px-3 py-1 rounded-md hover:bg-green-500 transition duration-200"
                    aria-label="Concluir nota"
                  >
                    Concluir
                  </button>
                  <button
                    onClick={() => handleDelete(nota.id)} // Atualizado para chamar handleDelete
                    className="bg-red-600 text-white px-3 py-1 rounded-md hover:bg-red-500 transition duration-200"
                    aria-label="Deletar nota"
                  >
                    Deletar
                  </button>
                </div>
              </li>
            ))
          ) : (
            <li className="text-gray-400 text-center">
              Nenhuma nota disponível.
            </li>
          )}
        </ul>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-500 transition duration-200"
        >
          Fechar
        </button>
      </div>
    </div>
  );
};

export default ModalNotas;
