// src/pages/RelatorioPage.js
import React from "react";
import { useNavigate } from "react-router-dom";

const RelatorioPage = () => {
  const navigate = useNavigate();

  const handleGerarRelatorio = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/relatorio`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "relatorio.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("Erro ao gerar o relatório");
      }
    } catch (error) {
      console.error("Erro:", error);
      alert("Ocorreu um erro ao gerar o relatório. Tente novamente.");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Gerar Relatório</h1>
      <button onClick={handleGerarRelatorio}>Baixar Relatório PDF</button>
    </div>
  );
};

export default RelatorioPage;
