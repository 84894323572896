import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext"; // Importar o hook useAuth

const apiUrl = process.env.REACT_APP_API_URL;

const ModalCliente = ({ cliente, isOpen, onClose, onStatusChange }) => {
  const [nota, setNota] = useState("");
  const [notas, setNotas] = useState([]);
  const [status, setStatus] = useState(cliente?.status || ""); // Adicionar estado para o status
  const { user } = useAuth(); // Acesso ao contexto de autenticação

  useEffect(() => {
    if (cliente && cliente.id) {
      // Lógica para buscar notas, se necessário
      axios
        .get(`${apiUrl}/clientes/${cliente.id}/notas`)
        .then((response) => setNotas(response.data))
        .catch((error) => console.error("Erro ao buscar notas:", error));
    }
  }, [cliente, isOpen]);

  const handleAddNota = () => {
    if (nota.trim() === "") return;

    const novaNota = {
      cliente_id: cliente.id,
      processo_id: null,
      nova: true,
      destinatario: cliente.nome,
      texto: nota,
      data_criacao: new Date(),
      criado_por_id: user ? user.first_name : null,
    };

    axios
      .post(`${apiUrl}/notas`, novaNota)
      .then((response) => {
        setNotas([...notas, response.data]);
        setNota("");

        // Agora busque o número do corretor e envie a notificação
        axios
          .get(`${apiUrl}/clientes/${cliente.id}/corretor`)
          .then((corretorResponse) => {
            const corretorId = corretorResponse.data.id;
            const corretorNumero = corretorResponse.data.telefone;

            // Envia a notificação ao corretor
            enviarNotificacao(corretorId, corretorNumero, nota);
          })
          .catch((error) => console.error("Erro ao buscar corretor:", error));
      })
      .catch((error) => console.error("Erro ao adicionar nota:", error));
  };

  const enviarNotificacao = (corretorId, telefone, nota) => {
    const status = `Uma nova nota foi adicionada: ${nota}`;

    axios
      .post(`${apiUrl}/notificar`, { corretorId, status })
      .then(() => {
        console.log("Notificação enviada com sucesso.");
      })
      .catch((error) => {
        console.error("Erro ao enviar notificação:", error);
      });
  };

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);

    axios
      .patch(`${apiUrl}/clientes/${cliente.id}/status`, { status: newStatus })
      .then(async (response) => {
        console.log("Status atualizado com sucesso:", response.data);

        const clienteAtualizado = response.data.cliente;

        // Notifique o corretor
        if (clienteAtualizado && clienteAtualizado.corretorId) {
          await notifyCorretor(clienteAtualizado.corretorId, newStatus);
        }

        // Notificar o componente pai
        onStatusChange();
        alert("Status atualizado com sucesso!");
      })
      .catch((error) => console.error("Erro ao atualizar status:", error));
  };

  const notifyCorretor = async (corretorId, status) => {
    try {
      console.log(
        `Notificando o corretor ${corretorId} sobre o status: ${status}`
      );
      await axios.post(`${apiUrl}/notificar`, { corretorId, status });
      console.log("Notificação enviada com sucesso ao corretor.");
    } catch (error) {
      console.error("Erro ao enviar notificação ao corretor:", error);
    }
  };

  const handleDocumentClick = (path) => {
    window.open(`${apiUrl}/${path}`, "_blank");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div className="bg-[#1a2238] text-white p-6 rounded-lg shadow-xl w-full max-w-4xl overflow-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Detalhes do Cliente</h2>
          <button onClick={onClose} className="text-white text-2xl">
            &times;
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <strong>Nome:</strong> {cliente.nome}
          </div>
          <div>
            <strong>Email:</strong> {cliente.email}
          </div>
          <div>
            <strong>Telefone:</strong> {cliente.telefone}
          </div>
          <div>
            <strong>CPF:</strong> {cliente.cpf}
          </div>
          <div>
            <strong>Renda:</strong> {cliente.valor_renda}
          </div>
          <div>
            <strong>Estado Civil:</strong> {cliente.estado_civil}
          </div>
          <div>
            <strong>Naturalidade:</strong> {cliente.naturalidade}
          </div>
          <div>
            <strong>Profissão:</strong> {cliente.profissao}
          </div>
          <div>
            <strong>Data de Admissão:</strong>{" "}
            {new Date(cliente.data_admissao).toLocaleDateString()}
          </div>
          <div>
            <strong>Data de Nascimento:</strong>{" "}
            {new Date(cliente.data_nascimento).toLocaleDateString()}
          </div>
          <div>
            <strong>Tipo de Renda:</strong> {cliente.renda_tipo}
          </div>
          <div>
            <strong>Carteira de Trabalho (Mais de 3 Anos):</strong>{" "}
            {cliente.carteira_tres_anos ? "Sim" : "Não"}
          </div>
          <div>
            <strong>Possui Dependente:</strong>{" "}
            {cliente.possui_dependente ? "Sim" : "Não"}
          </div>
          <div className="col-span-2">
            <strong>Status:</strong>
            <select
              value={status}
              onChange={handleStatusChange}
              className="ml-2 bg-[#2e3b55] text-white p-2 rounded-lg border border-gray-600 w-full"
            >
              <option value="aguardando_aprovacao">Aguardando Aprovação</option>
              <option value="reprovado">Cliente Reprovado</option>
              <option value="condicionado">Cliente Condicionado</option>
              <option value="cliente_aprovado">Cliente Aprovado</option>
              <option value="documentacao_pendente">
                Documentação Pendente
              </option>
              <option value="aguardando_cancelamento_qv">
                Aguardando Cancelamento / QV
              </option>
              <option value="proposta_apresentada">Proposta Apresentada</option>
              <option value="visita_efetuada">Visita Efetuada</option>
              <option value="fechamento_proposta">Fechamento Proposta</option>
              <option value="processo_em_aberto">Processo Aberto</option>
              <option value="conformidade">Conformidade</option>
              <option value="concluido">Venda Concluída</option>
              <option value="nao_deu_continuidade">Não Deu Continuidade</option>
            </select>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-300 mb-1">
            Adicionar Nota:
          </label>
          <textarea
            value={nota}
            onChange={(e) => setNota(e.target.value)}
            className="w-full bg-[#2e3b55] text-white p-3 rounded-lg border border-gray-600"
            rows="4"
          ></textarea>
          <button
            onClick={handleAddNota}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg mt-2 hover:bg-blue-700 transition-colors duration-200"
          >
            Adicionar Nota
          </button>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-2">Documentação</h3>
          {cliente.documentos_pessoais && (
            <button
              onClick={() => handleDocumentClick(cliente.documentos_pessoais)}
              className="block w-full bg-gray-700 text-white px-4 py-2 rounded-lg mb-2 hover:bg-gray-800 transition-colors duration-200"
            >
              Ver Documentos Pessoais
            </button>
          )}
          {cliente.extrato_bancario && (
            <button
              onClick={() => handleDocumentClick(cliente.extrato_bancario)}
              className="block w-full bg-gray-700 text-white px-4 py-2 rounded-lg mb-2 hover:bg-gray-800 transition-colors duration-200"
            >
              Ver Extrato Bancário
            </button>
          )}
          {cliente.estado_civil === "casado" && cliente.documentos_conjuge && (
            <button
              onClick={() => handleDocumentClick(cliente.documentos_conjuge)}
              className="block w-full bg-gray-700 text-white px-4 py-2 rounded-lg mb-2 hover:bg-gray-800 transition-colors duration-200"
            >
              Ver Documentos Cônjuge
            </button>
          )}
          {cliente.possui_dependente && cliente.documentos_dependente && (
            <button
              onClick={() => handleDocumentClick(cliente.documentos_dependente)}
              className="block w-full bg-gray-700 text-white px-4 py-2 rounded-lg mb-2 hover:bg-gray-800 transition-colors duration-200"
            >
              Ver Documentos Dependente
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalCliente;
