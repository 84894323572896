import React, { useState, useEffect } from "react";
import { FaSearch, FaWhatsapp, FaSignInAlt, FaUser } from "react-icons/fa"; // Importando os ícones
import { motion } from "framer-motion"; // Importando a biblioteca para animações

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // Estado para controlar a visibilidade do menu
  const [showSubmenu, setShowSubmenu] = useState(true); // Estado para controlar a visibilidade do submenu

  // Função para alternar o menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Função para detectar a rolagem da página
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setShowSubmenu(false);
    } else {
      setShowSubmenu(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll); // Adiciona o evento de rolagem
    return () => {
      window.removeEventListener("scroll", handleScroll); // Remove o evento ao desmontar
    };
  }, []);

  return (
    <>
      {/* Seção de alerta */}
      <div className="bg-[#78b439] text-white text-center py-2 fixed w-full z-20 top-0">
        <p className="text-sm font-semibold">Bem-vindo ao nosso site! Aproveite nossas ofertas especiais.</p>
      </div>

      {/* Navbar Desktop */}
      <nav className="hidden md:block bg-white fixed w-full z-20 top-8 start-0 border-t border-gray-300 shadow-md">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          {/* Logo */}
          <a href="#" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img
              src="/logo.png"
              className="h-12 filter invert" // Aumentando o tamanho da logo
              alt="Logo"
            />
          </a>

          {/* Barra de busca */}
          <div className="flex-grow mx-8">
            <form
              id="frmBusca"
              role="search"
              method="GET"
              action="/busca/"
              className="w-full"
            >
              <div className="relative">
                <input
                  type="text"
                  id="buscaTopo"
                  name="busca"
                  placeholder="O que você procura?"
                  className="w-full py-2 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#78b439]"
                />
                <button
                  type="submit"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#78b439] hover:text-green-700"
                >
                  <FaSearch /> {/* Ícone de busca */}
                </button>
              </div>
            </form>
          </div>

          {/* Ícones e links à direita */}
          <div className="flex items-center space-x-6">
            <div className="text-sm text-gray-800">
              <p className="flex items-center space-x-1">
                <FaUser /> {/* Ícone de usuário */}
                <span>Olá,</span>
              </p>
              <p>
                <a href="/login" className="font-semibold text-[#78b439] hover:underline">
                  Entre
                </a>{" "}
                ou{" "}
                <a href="/cadastro" className="font-semibold text-[#78b439] hover:underline">
                  Cadastre-se
                </a>
              </p>
            </div>
            <a
              href="https://wa.me/numero"
              className="bg-[#78b439] text-white px-4 py-2 rounded-lg font-semibold hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-[#78b439] flex items-center space-x-2"
            >
              <FaWhatsapp /> {/* Ícone do WhatsApp */}
              <span>Dúvidas? Chama no WhatsApp!</span>
            </a>
            <a
              href="/login"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 flex items-center space-x-2"
            >
              <FaSignInAlt /> {/* Ícone de Login */}
              <span>Login</span>
            </a>
          </div>
        </div>

        {/* Submenu */}
        {showSubmenu && (
          <motion.div
            initial={{ opacity: 0, height: 0 }} // Estado inicial da animação
            animate={{ opacity: 1, height: "auto" }} // Estado final da animação
            exit={{ opacity: 0, height: 0 }} // Estado de saída da animação
            transition={{ duration: 0.3 }} // Duração da animação
            className="border-t border-gray-300 mb-1 bg-white" // Adicionando borda superior e cor de fundo
          >
            <div className="flex justify-center items-center w-full"> {/* Centralizando o conteúdo */}
              <ul className="flex space-x-4 m-0 p-0"> {/* Lista de links com espaçamento */}
                <li><a href="/imoveis-novos" className="block px-4 py-2 hover:text-[#78b439]">Imóveis Novos</a></li>
                <li><a href="/imoveis-usados" className="block px-4 py-2 hover:text-[#78b439]">Usados</a></li>
                <li><a href="/agio" className="block px-4 py-2 hover:text-[#78b439]">Ágio</a></li>
                <li><a href="/valparaiso" className="block px-4 py-2 hover:text-[#78b439]">Valparaíso de Goiás</a></li>
                <li><a href="/cidade-ocidental" className="block px-4 py-2 hover:text-[#78b439]">Cidade Ocidental</a></li>
                <li><a href="/luziania" className="block px-4 py-2 hover:text-[#78b439]">Luziania</a></li>
                <li><a href="/jardim-inga" className="block px-4 py-2 hover:text-[#78b439]">Jardim Ingá</a></li>
                <li><a href="/brasilia" className="block px-4 py-2 hover:text-[#78b439]">Brasília</a></li>
              </ul>
            </div>
          </motion.div>
        )}
      </nav>

      {/* Navbar Mobile */}
      <nav className="md:hidden bg-white fixed w-full z-20 top-8 border-t border-gray-300 shadow-md">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          {/* Logo */}
          <a href="#" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src="/logo.png" className="h-12 filter invert" alt="Logo" />
          </a>

          {/* Botão de menu */}
          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-gray-400 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300"
            aria-controls="navbar-sticky"
            aria-expanded={isOpen}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>

        {/* Menu Mobile */}
        {isOpen && (
          <div className="bg-white flex flex-col items-center p-4 border-t border-gray-300">
            <form
              id="frmBusca"
              role="search"
              method="GET"
              action="/busca/"
              className="w-full p-4"
            >
              <div className="relative">
                <input
                  type="text"
                  id="buscaTopo"
                  name="busca"
                  placeholder="O que você procura?"
                  className="w-full py-2 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#78b439]"
                />
                <button
                  type="submit"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#78b439] hover:text-green-700"
                >
                  <FaSearch />
                </button>
              </div>
            </form>

            <ul className="flex flex-col space-y-2 mt-4">
              <li><a href="/imoveis-novos" className="block px-4 py-2 hover:text-[#78b439]">Imóveis Novos</a></li>
              <li><a href="/imoveis-usados" className="block px-4 py-2 hover:text-[#78b439]">Usados</a></li>
              <li><a href="/agio" className="block px-4 py-2 hover:text-[#78b439]">Ágio</a></li>
              <li><a href="/valparaiso" className="block px-4 py-2 hover:text-[#78b439]">Valparaíso de Goiás</a></li>
              <li><a href="/cidade-ocidental" className="block px-4 py-2 hover:text-[#78b439]">Cidade Ocidental</a></li>
              <li><a href="/luziania" className="block px-4 py-2 hover:text-[#78b439]">Luziania</a></li>
              <li><a href="/jardim-inga" className="block px-4 py-2 hover:text-[#78b439]">Jardim Ingá</a></li>
              <li><a href="/brasilia" className="block px-4 py-2 hover:text-[#78b439]">Brasília</a></li>
            </ul>

            <div className="flex flex-col space-y-2 mt-4">
              <div className="text-sm text-gray-800">
                <p className="flex items-center space-x-1">
                  <FaUser />
                  <span>Olá,</span>
                </p>
                <p>
                  <a href="/login" className="font-semibold text-[#78b439] hover:underline">
                    Entre
                  </a>{" "}
                  ou{" "}
                  <a href="/cadastro" className="font-semibold text-[#78b439] hover:underline">
                    Cadastre-se
                  </a>
                </p>
              </div>
              <a
                href="https://wa.me/numero"
                className="bg-[#78b439] text-white px-4 py-2 rounded-lg font-semibold hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-[#78b439] flex items-center space-x-2"
              >
                <FaWhatsapp />
                <span>Dúvidas? Chama no WhatsApp!</span>
              </a>
              <a
                href="/login"
                className="bg-blue-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 flex items-center space-x-2"
              >
                <FaSignInAlt />
                <span>Login</span>
              </a>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
