import React, { useState, useEffect } from "react";

const ModalEditarCliente = ({ cliente, isOpen, onClose, onSave }) => {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cpf, setCpf] = useState("");
  const [valorRenda, setValorRenda] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");
  const [naturalidade, setNaturalidade] = useState("");
  const [profissao, setProfissao] = useState("");
  const [dataAdmissao, setDataAdmissao] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [rendaTipo, setRendaTipo] = useState("");
  const [possuiCarteiraMaisTresAnos, setPossuiCarteiraMaisTresAnos] =
    useState(false);
  const [numeroPis, setNumeroPis] = useState("");
  const [possuiDependente, setPossuiDependente] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (cliente) {
      setNome(cliente.nome || "");
      setEmail(cliente.email || "");
      setTelefone(cliente.telefone || "");
      setCpf(cliente.cpf || "");
      setValorRenda(cliente.valor_renda || "");
      setEstadoCivil(cliente.estado_civil || "");
      setNaturalidade(cliente.naturalidade || "");
      setProfissao(cliente.profissao || "");
      setDataAdmissao(
        cliente.data_admissao ? cliente.data_admissao.split("T")[0] : ""
      );
      setDataNascimento(
        cliente.data_nascimento ? cliente.data_nascimento.split("T")[0] : ""
      );
      setRendaTipo(cliente.renda_tipo || "");
      setPossuiCarteiraMaisTresAnos(
        cliente.possui_carteira_mais_tres_anos || false
      );
      setNumeroPis(cliente.numero_pis || "");
      setPossuiDependente(cliente.possui_dependente || false);
      setStatus(cliente.status || "");
    }
  }, [cliente]);

  const handleSave = () => {
    if (cliente) {
      onSave({
        ...cliente,
        nome,
        email,
        telefone,
        cpf,
        valor_renda: valorRenda,
        estado_civil: estadoCivil,
        naturalidade,
        profissao,
        data_admissao: dataAdmissao,
        data_nascimento: dataNascimento,
        renda_tipo: rendaTipo,
        possui_carteira_mais_tres_anos: possuiCarteiraMaisTresAnos,
        numero_pis: numeroPis,
        possui_dependente: possuiDependente,
        status,
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-gray-900 text-white p-6 rounded-lg shadow-lg max-w-4xl w-full overflow-auto">
        <h2 className="text-2xl font-bold mb-4">Editar Cliente</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {[
            { label: "Nome", type: "text", value: nome, onChange: setNome },
            { label: "Email", type: "email", value: email, onChange: setEmail },
            {
              label: "Telefone",
              type: "text",
              value: telefone,
              onChange: setTelefone,
            },
            { label: "CPF", type: "text", value: cpf, onChange: setCpf },
            {
              label: "Valor da Renda",
              type: "number",
              value: valorRenda,
              onChange: setValorRenda,
            },
            {
              label: "Estado Civil",
              type: "text",
              value: estadoCivil,
              onChange: setEstadoCivil,
            },
            {
              label: "Naturalidade",
              type: "text",
              value: naturalidade,
              onChange: setNaturalidade,
            },
            {
              label: "Profissão",
              type: "text",
              value: profissao,
              onChange: setProfissao,
            },
            {
              label: "Número do PIS",
              type: "text",
              value: numeroPis,
              onChange: setNumeroPis,
            },
            {
              label: "Tipo de Renda",
              type: "text",
              value: rendaTipo,
              onChange: setRendaTipo,
            },
          ].map(({ label, type, value, onChange }) => (
            <div key={label}>
              <label className="block text-sm font-medium mb-1">{label}:</label>
              <input
                type={type}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                className="border border-gray-700 bg-gray-800 p-2 rounded w-full text-white"
              />
            </div>
          ))}
          <div>
            <label className="block text-sm font-medium mb-1">
              Data de Admissão:
            </label>
            <input
              type="date"
              value={dataAdmissao}
              onChange={(e) => setDataAdmissao(e.target.value)}
              className="border border-gray-700 bg-gray-800 p-2 rounded w-full text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">
              Data de Nascimento:
            </label>
            <input
              type="date"
              value={dataNascimento}
              onChange={(e) => setDataNascimento(e.target.value)}
              className="border border-gray-700 bg-gray-800 p-2 rounded w-full text-white"
            />
          </div>
          <div className="col-span-2 flex items-center space-x-2">
            <input
              type="checkbox"
              checked={possuiCarteiraMaisTresAnos}
              onChange={(e) => setPossuiCarteiraMaisTresAnos(e.target.checked)}
              className="form-checkbox h-5 w-5 text-blue-500"
            />
            <label className="text-sm">Possui Carteira há mais de 3 anos</label>
          </div>
          <div className="col-span-2 flex items-center space-x-2">
            <input
              type="checkbox"
              checked={possuiDependente}
              onChange={(e) => setPossuiDependente(e.target.checked)}
              className="form-checkbox h-5 w-5 text-blue-500"
            />
            <label className="text-sm">Possui Dependente</label>
          </div>
          <div className="col-span-2">
            <label className="block text-sm font-medium mb-1">Status:</label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="border border-gray-700 bg-gray-800 p-2 rounded w-full text-white"
            >
              <option value="aguardando_aprovacao">Aguardando Aprovação</option>
              <option value="reprovado">Cliente Reprovado</option>
              <option value="Condicionado">Cliente Condicionado</option>
              <option value="cliente_aprovado">Cliente Aprovado</option>
              <option value="documentacao_pendente">
                Documentação Pendente
              </option>
              <option value="aguardando_cancelamento_qv">
                Aguardando Cancelamento / QV
              </option>
              <option value="proposta_apresentada">Proposta Apresentada</option>
              <option value="visita_efetuada">Visita Efetuada</option>
              <option value="fechamento_proposta">Fechamento Proposta</option>
              <option value="processo_em_aberto">Processo Aberto</option>
              <option value="conformidade">Conformidade</option>
              <option value="concluido">Venda Concluída</option>
              <option value="nao_deu_continuidade">Não Deu Continuidade</option>
            </select>
          </div>
        </div>
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded"
          >
            Cancelar
          </button>
          <button
            onClick={handleSave}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalEditarCliente;
