import React from 'react';
import MainLayout from '../layouts/MainLayout'; // Certifique-se de que o caminho está correto
import ListaProprietarios from '../components/ListaProprietarios'; // Atualize o caminho para ListaProprietarios

const ListaProprietariosPage = () => {
    return (
        <MainLayout>
            <ListaProprietarios />
        </MainLayout>
    );
}

export default ListaProprietariosPage;
